import { Link } from "react-router-dom";
import { Usuario } from "./Usuario";
import Carrito from "./Carrito";
import globals from "../globals";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useRef} from "react";


function Header() {
    const offCanvasRef = useRef();
    const closeOffCanvas = () => {
        if (!offCanvasRef.current.backdrop) return;
        offCanvasRef.current.backdrop.click();
    };
    return (
        <Navbar expand={'lg'} className={'navbar-principal'}>
            <Container fluid className="d-flex bd-highlight mb-0">
                <Navbar.Brand className="me-auto py-2 bd-highlight">
                    <Link to={'/'} className="navbar-brand">
                        <img src="/img/logo.svg" alt="DistriCentro Ferretera" className="hp-desktop"/>
                        <img src="/img/logo-mb.svg" alt="DistriCentro Ferretera" className="hp-mobile"/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                    ref={offCanvasRef}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                            <h4>Menú</h4>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {!globals.enContruccion &&
                            <Nav className={'justify-content-end flex-grow-1 pe-3'}>
                                <Nav.Item>
                                    <Link
                                        onClick={closeOffCanvas}
                                        to={'/'} className="nav-link"
                                    >Inicio</Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        onClick={closeOffCanvas}
                                        to={'/nosotros'} className="nav-link">Nosotros</Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        onClick={closeOffCanvas}
                                        to={'/novedades'} className="nav-link">Novedades</Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Link
                                        onClick={closeOffCanvas}
                                        to={'/catalogo'} className="nav-link">Catálogo</Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <a
                                        onClick={closeOffCanvas}
                                        className="nav-link" href="/nosotros/#contacto">Contacto</a>
                                </Nav.Item>

                                <Nav.Item className="mobile mt-auto logo-navbar-mb">
                                    <img src="/img/logo.svg" alt="DistriCentro Ferretera" />
                                </Nav.Item>
                            </Nav>
                        }
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                {!globals.enContruccion &&
                    <Usuario className="py-2 bd-highlight" />
                }
                {!globals.enContruccion && !globals.soloCatalogo &&
                    <Carrito />
                }
            </Container>
        </Navbar>
    );
}
export default Header;