import  { BrowserRouter } from 'react-router-dom';
import './App.css';
import ScrollToTop from "./utils/ScrollToTop";
import Header from './components/Header';
import Footer from "./components/Footer";
import Body from "./components/Body";
import Whatsapp from "./components/Whatsapp";
import Redes from "./components/Redes";
import globals from "./globals";
import Sidebar from "./components/sidebar/Sidebar";

function App() {
  return (
    <div>
      <BrowserRouter>
      <ScrollToTop />
          <div className="container-fluid px-0 justify-content-center d-flex">
              <div className="container px-0 mx-0 ">
                  <Header />
              </div>
          </div>

          <Body />

          <Footer />

          <Whatsapp nro={globals.whatsapp} />
          <Redes />
          <Sidebar />
      </BrowserRouter>
    </div>
  );
}

export default App;
