import { Link } from "react-router-dom";
import { cdnURL } from "../utils/api";
import { currencyFormat } from "../utils/utils";
import {useParams} from "react-router-dom";
import usuariosStore from "../stores/UsuariosStore";
import carritoStore from "../stores/CarritoStore";
import globals from "../globals";

function Producto(props) {
    const { cat, subcat, subsubcat } = useParams();
    let path = '/producto/';
    if (cat) path += cat + '/';
    if (subcat) path += subcat + '/';
    if (subsubcat) path += subsubcat + '/';
    path += `${props.prod.name.replace(' ', '_')}/${props.prod.id}`;
    const className = props.wide
        ? "col-lg-4 col-md-4 col-sm-6 col-12 d-flex flex-column mb-4"
        : props.tall
            ? "col-lg-4 col-md-6 col-sm-12 col-12 d-flex flex-column mb-4"
            : "col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column mb-4";
    const imagen = props.prod.image1thumb || props.prod.image2thumb || props.prod.image3thumb;
    return (
        <div className={className}>
            <div className="producto d-flex flex-column">
                {/*<div className="tag">{props.prod.category_name}</div>*/}
                <div className="tag">{props.prod.family_name}</div>
                <div className="img-producto">
                    <Link to={path}>
                        <img alt='' src={cdnURL(imagen, "/img/producto.png")} />
                    </Link>
                </div>
                <div className="info-producto mt-3">
                    <h6 className="id-producto">{props.prod.original_product_id}</h6>
                    <h5 className="nombre-producto">{props.prod.name}</h5>
                    { props.prod.sale_price_pes >= 0 &&
                        <h5 className="precio">{currencyFormat(props.prod.sale_price_pes)}</h5>
                    }
                    <h6 className="marca-producto">{props.prod.brand}</h6>
                </div>
                <div className="mt-auto">
                    <Link to={path}> <button> Ver detalle </button> </Link>
                {usuariosStore.logged && !globals.soloCatalogo &&
                    <button className={'agregar-carrito'}
                            onClick={() => carritoStore.agregar(
                                {
                                    id: props.prod.id,
                                    name: props.prod.name,
                                    prod_id: props.prod.original_product_id,
                                    brand: props.prod.brand,
                                    price: props.prod.sale_price_pes,
                                    imagen: imagen
                                }, 1)
                            }
                    >Agregar al Carrito</button>
                }
                </div>
            </div>
        </div>
    );
}
export default Producto;