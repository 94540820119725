import {action, makeObservable, observable, runInAction} from 'mobx';
import { isMobile } from "react-device-detect";

class SidebarStore {
    @observable open = false;
    @observable width = isMobile ? '100vw' : '40vw';
    @observable component;
    defaultWidth = isMobile ? '100vw' : '40vw';

    constructor() {
        makeObservable(this);
    }

    @action
    toogle(component) {
        this.setOpen(!this.open);
        this.component = component;
    }

    @action
    show(component, width) {
        this.setOpen(false);
        this.setOpen(true);
        this.component = component;
        if (isMobile) {
            this.width = '100vw';
        } else if (width) {
            this.width = width;
        } else {
            this.width = this.defaultWidth;
        }
    }

    @action
    hide() {
        console.log('hide');
        this.setOpen(false);
    }

    @action
    setOpen(value) {
        this.open = value;
    }
}

const sidebarStore = new SidebarStore();
export default sidebarStore;