import React, {useEffect, useRef} from "react";
import { observer } from "mobx-react";
import "./Sidebar.css";
import sidebarStore from "./SidebarStore";

function Sidebar() {
    //const [scrolled, setScrolled] = useState(false);
    const wrapperRef = useRef();
    console.log('sidebar', sidebarStore);

    const handleClickOutside = (event) => {
        console.log('handle');
        if (sidebarStore.open &&
            wrapperRef &&
            !wrapperRef.current.contains(event.target)
        ) {
            sidebarStore.hide();
            event.stopPropagation();
        }
    }

    //const listenToScroll = () => {
    //    setScrolled(document.body.scrollTop > 0);
    //}

    useEffect(() => {
        //document.body.addEventListener('scroll', listenToScroll);
        document.body.addEventListener('click', handleClickOutside);
        //document.body.removeEventListener('scroll', listenToScroll);
        document.body.removeEventListener('click', handleClickOutside);
    });

    return (
        <div className={ sidebarStore.open
            ? 'modal-container'
            : 'modal-container modal-container-close'}
             style={{width: sidebarStore.width }}
             ref={wrapperRef}
        >
            <div className="row cerrar-modal"
                 style={{paddingRight: '5px'}}
                 onClick={() => sidebarStore.hide()}>
                <span><i className="fa fa-times"></i> </span>
            </div>
            <div className="modal-content">
                {sidebarStore.component}
            </div>
        </div>
    );
}
export default observer(Sidebar);
