import { runInAction, toJS, reaction, action, autorun, observable, makeObservable } from "mobx";
import { apiRequest } from "../utils/api";
import globals from "../globals";
import usuariosStore from "./UsuariosStore";

class CarritoStore {
    @observable showCarritoModal = false;
    @observable carrito = [];

    constructor() {
        const carrito = localStorage.getItem('carrito');
        try {
            this.carrito = JSON.parse(carrito);
        } catch(e) {
            this.carrito = [];
        }
        makeObservable(this);
    }

    persistData() {
        console.log('persistData', toJS(this.carrito));
        localStorage.setItem('carrito', JSON.stringify(toJS(this.carrito)));
    }

    cant() {
        return 1;
        //return this.carrito.reduce((a, b) => a + b.cant, 0);
    }

    @action
    setCantIdx(cant, idx) {
        this.carrito[idx].cant = parseInt(cant);
        this.persistData();
    }

    @action
    borrarIdx(idx) {
        this.carrito.splice(idx, 1);
        this.persistData();
    }

    @action
    modal(value) {
        if (value !== undefined) {
            this.showCarritoModal = value;
        } else {
             this.showCarritoModal = !this.showCarritoModal;
        }
    }

    @action
    agregar(producto, cant) {
        cant = parseInt(cant);
        const prod = this.carrito.filter(p => p.id == producto.id);
        if (prod.length > 0) {
            const idx = this.carrito.findIndex(p => p.id == producto.id);
            this.carrito[idx].cant += cant;
        } else {
            this.carrito.push({...producto, cant});
        }
        this.persistData();
    }
}
const carritoStore = new CarritoStore();
export default carritoStore;